import BezierEasing from 'bezier-easing'
import {
  easeInQuad,
  easeOutQuad,
  easeInOutQuad,
  easeInCubic,
  easeOutCubic,
  easeInOutCubic,
  easeInQuart,
  easeOutQuart,
  easeInOutQuart,
  easeInQuint,
  easeOutQuint,
  easeInOutQuint,
  easeInSine,
  easeOutSine,
  easeInOutSine,
  easeInExpo,
  easeOutExpo,
  easeInOutExpo,
  easeInCirc,
  easeOutCirc,
  easeInOutCirc,
  easeInElastic,
  easeOutElastic,
  easeInOutElastic,
  easeInBack,
  easeOutBack,
  easeInOutBack,
  easeInBounce,
  easeOutBounce,
  easeInOutBounce
} from 'js-easing-functions'
var easingList = {
  easeInQuad,
  easeinquad: easeInQuad,
  easeOutQuad,
  easeoutquad: easeOutQuad,
  easeInOutQuad,
  easeinoutquad: easeInOutQuad,
  easeInCubic,
  easeincubic: easeInCubic,
  easeOutCubic,
  easeoutcubic: easeOutCubic,
  easeInOutCubic,
  easeinoutcubic: easeInOutCubic,
  easeInQuart,
  easeinquart: easeInQuart,
  easeOutQuart,
  easeoutquart: easeOutQuart,
  easeInOutQuart,
  easeinoutquart: easeInOutQuart,
  easeInQuint,
  easeinquint: easeInQuint,
  easeOutQuint,
  easeoutquint: easeOutQuint,
  easeInOutQuint,
  easeinoutquint: easeInOutQuint,
  easeInSine,
  easeinsine: easeInSine,
  easeOutSine,
  easeoutsine: easeOutSine,
  easeInOutSine,
  easeinoutsine: easeInOutSine,
  easeInExpo,
  easeinexpo: easeInExpo,
  easeOutExpo,
  easeoutexpo: easeOutExpo,
  easeInOutExpo,
  easeinoutexpo: easeInOutExpo,
  easeInCirc,
  easeincirc: easeInCirc,
  easeOutCirc,
  easeoutcirc: easeOutCirc,
  easeInOutCirc,
  easeinoutcirc: easeInOutCirc,
  easeInElastic,
  easeinelastic: easeInElastic,
  easeOutElastic,
  easeoutelastic: easeOutElastic,
  easeInOutElastic,
  easeinoutelastic: easeInOutElastic,
  easeInBack,
  easeinback: easeInBack,
  easeOutBack,
  easeoutback: easeOutBack,
  easeInOutBack,
  easeinoutback: easeInOutBack,
  easeInBounce,
  easeinbounce: easeInBounce,
  easeOutBounce,
  easeoutbounce: easeOutBounce,
  easeInOutBounce,
  easeinoutbounce: easeInOutBounce,
  linear,
}
export default function getEasing(name) {
  if (easingList[name]) {
    return easingList[name]
  }
  var t = name.match(/cubic-bezier\([\s\S]*\)/)
  if (t) {
    var data = t[0].split(/[,\(\)]/)
    return () => {
      var k = elapsed / duration
      var v = BezierEasing(Number(data[1]), Number(data[2]), Number(data[3]), Number(data[4]))(k)
      return (amountOfChange - initialValue) * v + initialValue
    }
  }
  return linear
}
function linear(elapsed, initialValue, amountOfChange, duration) {
  var k = elapsed / duration
  return (amountOfChange - initialValue) * k + initialValue
}
function doEasing(type,value,target,time) {

}
