import EasingPark from './easingPark'
export default class {
  constructor(frames, defaultFrames, timelength, frameIn, frameEnd, getIntermediateFrame) {
    this.frames = frames || []
    this.defaultFrames = defaultFrames
    this.timelength = timelength
    if (frameIn) {
      this.frameIn = frameIn
    } else {
      this.frameIn = (now, next) => { }
    }
    if (frameEnd) {
      this.frameEnd = frameEnd
    } else {
      this.frameEnd = (now, next) => { }
    }
    if (getIntermediateFrame) {
      this.getIntermediateFrame = getIntermediateFrame
    } else {
      this.getIntermediateFrame = (front, behind, time) => {
        var t = time - front.time
        var d = behind.time - front.time
        var obj = makeTransitionObj(t, front, behind, d)
        console.log('defaultT', obj, t, front, behind, d)
        return obj
      }
    }
  }
  getFrame(time) {
    var index = this.getFrameIndex(time)
    var nf = this.getFrameByIndex(index + 1)
    var f = this.getFrameByIndex(index)
    console.log('getFrame', time, index, f, nf)
    return this.getIntermediateFrame(f, nf, time)
  }
  getFrameIndex(time) {//返回-1到length
    var kfIndex = -1
    for (var i = 0; i < this.frames.length; i++) {
      var kf = this.frames[i]
      if (kf.time <= time) {
        kfIndex = i
      }
    }
    return kfIndex
  }
  getFrameByIndex(index) {
    console.log('getFrameByIndex', index)
    if (this.frames.length > 0) {
      if (index < 0) {
        console.log('start')
        return { ...this.frames[0], time: 0 }
      } else if (index >= this.frames.length) {
        console.log('end')
        return { ...this.frames[this.frames.length - 1], time: this.timelength }
      } else {
        console.log('nomail')
        return this.frames[index]
      }
    } else {
      console.log('length 0')
      if (index == -1) {
        return { ...this.defaultFrames, time: 0 }
      }
      if (index == 0) {
        return { ...this.defaultFrames, time: this.timelength }
      }
      return this.defaultFrames
    }
  }
  play(time, endcallback) {
    if (this.plan) {
      var etime = this.nowFrame.time + (new Date().getTime() - this.planTime.getTime())
      if (this.endcallback) {
        this.endcallback(etime)
      }
    }

    this.endcallback = endcallback
    this.nowFrameIndex = this.getFrameIndex(time)
    var nextFrame = this.getFrameByIndex(this.nowFrameIndex + 1)
    this.nowFrame = this.getIntermediateFrame(this.getFrameByIndex(this.nowFrameIndex), nextFrame, time)
    this.frameIn(this.nowFrame, nextFrame)
    this.playTimePoint = time
    this.playTime = this.planTime = new Date()
    this.plan = setTimeout(() => {
      this.plan = null
      this.nextFrame()
    }, nextFrame.time - this.nowFrame.time)
  }
  nextFrame() {
    var f = this.getFrameByIndex(this.nowFrameIndex + 1)
    this.frameEnd(this.nowFrame, f)
    this.nowFrameIndex++
    if (this.nowFrameIndex >= this.frames.length) {
      if (this.endcallback) {
        this.endcallback(f.time)
      }
    } else {
      var nextFrame = this.getFrameByIndex(this.nowFrameIndex + 1)
      this.nowFrame = f
      this.frameIn(this.nowFrame, nextFrame)
      this.planTime = new Date()
      this.plan = setTimeout(() => {
        this.plan = null
        this.nextFrame()
      }, nextFrame.time - ((new Date().getTime() - this.playTime.getTime()) + this.playTimePoint))
    }
  }
  stop(func) {
    if (this.plan) {
      clearTimeout(this.plan)
      this.plan = null
    }
    var time = null
    if (this.planTime) {
      time = this.nowFrame.time + (new Date().getTime() - this.planTime.getTime())
      this.planTime = null
    }
    if (func) {
      func({ time, nowf: this.nowFrame, nextf: this.nextFrame })
    }
    return time
  }
  destroy() {
    console.log('destroy keyFrames')
    this.stop
    if (this.plan) {
      clearTimeout(this.plan)
    }
  }
  static makeTransitionObj = makeTransitionObj
}
function makeTransitionObj(t, f1, f2, d) {
  var no
  if (f1 instanceof Array) {
    no = []
  } else {
    no = {}
  }
  for (var i in f1) {
    var a = f1[i], b = f2[i]
    var Easingtype = 'linear'
    if (typeof a == 'number' && typeof b == 'number') {
      no[i] = EasingPark(Easingtype)(t, a, b, d)
    } else if (typeof a == 'object' && a != null && typeof b == 'object' && b != null) {
      no[i] = makeTransitionObj(t, a, b, d)
    } else if (typeof a == 'string' && typeof b == 'string') {
      if (a.match(/#[0-9a-f]{3,8}/) && b.match(/#[0-9a-f]{3,8}/)) {
        var colorA = new Color(a)
        var colorB = new Color(b)
        var colorC = new Color()
        colorC.rad = EasingPark(Easingtype)(t, colorA.rad, colorB.rad, d)
        colorC.green = EasingPark(Easingtype)(t, colorA.green, colorB.green, d)
        colorC.blue = EasingPark(Easingtype)(t, colorA.blue, colorB.blue, d)
        colorC.alpha = EasingPark(Easingtype)(t, colorA.alpha, colorB.alpha, d)
        no[i] = colorC.getColor()
      } else {
        no[i] = a
      }
    } else {
      no[i] = a
    }
  }
  return no
}
class Color {
  constructor(color) {
    if (!color) {
      this.rad = 0
      this.green = 0
      this.blue = 0
      this.alpha = 0
      return
    }
    var arr
    if (arr = color.match(/#[0-9a-f]{8}/)) {
      var c = arr[0]
      this.rad = Number(`0x${c[1]}${c[2]}`)
      this.green = Number(`0x${c[3]}${c[4]}`)
      this.blue = Number(`0x${c[5]}${c[6]}`)
      this.alpha = Number(`0x${c[7]}${c[8]}`)
    } else if (arr = color.match(/#[0-9a-f]{6}/)) {
      var c = arr[0]
      this.rad = Number(`0x${c[1]}${c[2]}`)
      this.green = Number(`0x${c[3]}${c[4]}`)
      this.blue = Number(`0x${c[5]}${c[6]}`)
      this.alpha = 255

    } else if (arr = color.match(/#[0-9a-f]{4}/)) {
      var c = arr[0]
      this.rad = Number(`0x${c[1]}${c[1]}`)
      this.green = Number(`0x${c[2]}${c[2]}`)
      this.blue = Number(`0x${c[3]}${c[3]}`)
      this.alpha = Number(`0x${c[4]}${c[4]}`)
    } else if (arr = color.match(/#[0-9a-f]{3}/)) {
      var c = arr[0]
      this.rad = Number(`0x${c[1]}${c[1]}`)
      this.green = Number(`0x${c[2]}${c[2]}`)
      this.blue = Number(`0x${c[3]}${c[3]}`)
      this.alpha = 255
    } else {
      this.rad = 0
      this.green = 0
      this.blue = 0
      this.alpha = 0
    }
  }
  getColor() {
    if (this.red > 0xff) {
      this.red = 0xff
    }
    if (this.green > 0xff) {
      this.green = 0xff
    }
    if (this.blue > 0xff) {
      this.blue = 0xff
    }
    if (this.alpha > 0xff) {
      this.alpha = 0xff
    }
    var sr = Number(this.rad.toFixed(0)).toString(16)
    if (sr.length == 1) {
      sr = '0' + sr
    }
    var sg = Number(this.green.toFixed(0)).toString(16)
    if (sg.length == 1) {
      sg = '0' + sg
    }
    var sb = Number(this.blue.toFixed(0)).toString(16)
    if (sb.length == 1) {
      sb = '0' + sb
    }
    var sa = Number(this.alpha.toFixed(0)).toString(16)
    if (sa.length == 1) {
      sa = '0' + sa
    }
    return `#${sr}${sg}${sb}${sa}`
  }
}
